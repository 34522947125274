import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import withProfile from 'src/components/withProfile';
import AuthService from 'src/services/authenticate';
import { AppStateContext } from 'src/stores';
import MediaQuery from 'react-responsive';
import i18n from 'src/i18n';
import { ErrorToast } from 'src/libs/toast';

import bubbleBg from 'src/assets/menu/bubbleBg.png';
import defaultAvatar from 'src/assets/menu/defaultAvatar.png';
import BasicInfoIcon from 'src/assets/menu/basicInfo.svg';
import SubscriptionIcon from 'src/assets/menu/subscriptionIcon.svg';
import CouponIcon from 'src/assets/menu/coupon.svg';
import RedeemIcon from 'src/assets/menu/redeem.svg';
import OrderIcon from 'src/assets/menu/order.svg';
import HistoryIcon from 'src/assets/menu/history.svg';
import FavoIcon from 'src/assets/menu/favo.svg';
import LogoutIcon from 'src/assets/menu/logout.svg';
import arrowDown from 'src/assets/header/arrowDown.svg';

import clsx from 'clsx';
import MenuViewModel from '../Menu/viewModule';

import styles from './pxStyles.module.scss';

@observer
class ProfileNavView extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);
    this.menuVM = new MenuViewModel();
  }

  componentDidMount() {
    this.menuVM.didMount();
  }

  componentWillUnmount() {
    this.menuVM.unMount();
  }

  handlerSignOut = async () => {
    try {
      // 登出的 api.
      const res = await AuthService.logout();
      // 關閉 ProfileNav menu.
      this.menuVM.handlerCloseMenu();
      // 更新 context profile.
      // this.context.actions.updateProfile(null);
      // 重新整理.
      window.location.reload();
    } catch (error) {
      ErrorToast(`${i18n.t('api_common_general')}: ${error.response?.status}`);
    }
  }

  render() {
    const { textStyle, profile, headerHeight, width } = this.props;
    const { avatar, nickname, points } = profile;

    return (
      <div className={clsx(styles.userContainer, styles.userContainer_layout)}>
        <div
          className={styles.flexBox}
          onClick={this.menuVM.isOpen ? this.menuVM.handlerCloseMenu : this.menuVM.handlerOpenMenu}
        >
          <div className={clsx(styles.userContainer_item)}>
            <div
              style={{ '--src': `url(${this.props.profile.avatar ?? defaultAvatar})` }}
              className={clsx(styles.user_avatar, styles.user_avatar_layout)}
            />
          </div>
          <div className={clsx(styles.userContainer_spacer)} />
          <div className={clsx(styles.userContainer_item1)}>
            <div
              className={clsx(textStyle)}

            >
              {nickname}
            </div>
            <div
              style={{ '--src': `url(${arrowDown})` }}
              className={clsx(styles.icon2, styles.icon2_layout, this.menuVM.isOpen && styles.rotate)}
            />
          </div>
        </div>

        {
          this.menuVM.isOpen && (
            <div
              className={styles.curtain}
              onClick={this.menuVM.handlerCloseMenu}
            />
          )
        }
        {
          this.menuVM.isOpen && (
            <>
              <MediaQuery minHeight={700}>
                <div className={clsx(styles.container)} style={width < 576 ? { top: headerHeight } : { top: 30 }}>
                  <div className={clsx(styles.dropDownContainer)}>
                    <div style={{ '--src': `url(${bubbleBg})` }} className={clsx(styles.infoContainer, styles.infoContainer_layout)}>
                      <div className={clsx(styles.infoContent, styles.infoContent_layout)}>
                        <div className={clsx(styles.infoFlex, styles.infoFlex_layout)}>
                          <div className={clsx(styles.infoFlex_item)}>
                            <div style={{ '--src': `url(${avatar ?? defaultAvatar})` }} className={clsx(styles.userIcon, styles.userIcon_layout)} />
                          </div>
                          <div className={clsx(styles.infoFlex_spacer)} />
                          <div className={clsx(styles.infoFlex_item1)}>
                            <div className={clsx(styles.userInfo, styles.userInfo_layout)}>
                              <h5 className={clsx(styles.userName, styles.userName_layout)}>
                                {nickname}
                              </h5>
                              <div className={clsx(styles.userPoint, styles.userPoint_layout)}>
                                {`${i18n.t('profile_my_point_left')} ${points} ${i18n.t('profile_my_point_left_unit')}`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={clsx(styles.naviContainer, styles.naviContainer_layout)}>
                      <div className={clsx(styles.accountNav, styles.accountNav_layout)}>
                        <h3 className={clsx(styles.accountText, styles.accountText_layout)}>
                          {i18n.t('profile_my_account')}
                        </h3>
                      </div>

                      <div className={clsx(styles.accountNavFlex, styles.accountNavFlex_layout)}>
                        <Link
                          to="/profile/information"
                          onClick={this.menuVM.handlerCloseMenu}
                          className={clsx(styles.basicInfoFlex, styles.basicInfoFlex_layout)}
                        >
                          <div className={clsx(styles.basicInfoFlex_item)}>
                            <div style={{ '--src': `url(${BasicInfoIcon})` }} className={clsx(styles.basicInfoIcon, styles.basicInfoIcon_layout)} />
                          </div>
                          <div className={clsx(styles.basicInfoFlex_spacer)} />
                          <h5 className={clsx(styles.basicInfoText, styles.basicInfoText_layout)}>
                            {i18n.t('profile_basic_information')}
                          </h5>
                        </Link>

                        <Link
                          to="/profile/subscription-status"
                          onClick={this.menuVM.handlerCloseMenu}
                          className={clsx(styles.basicInfoFlex, styles.basicInfoFlex_layout)}
                        >
                          <div className={clsx(styles.basicInfoFlex_item)}>
                            <div style={{ '--src': `url(${SubscriptionIcon})` }} className={clsx(styles.basicInfoIcon, styles.basicInfoIcon_layout)} />
                          </div>
                          <div className={clsx(styles.basicInfoFlex_spacer)} />
                          <h5 className={clsx(styles.basicInfoText, styles.basicInfoText_layout)}>
                            {i18n.t('profile_subscription_status')}
                          </h5>
                        </Link>

                        <Link to="/profile/coupon" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.couponFlex, styles.couponFlex_layout)}>
                          <div className={clsx(styles.couponFlex_item)}>
                            <img src={CouponIcon} alt="coupon" className={clsx(styles.couponIcon, styles.couponIcon_layout)} />
                          </div>
                          <div className={clsx(styles.couponFlex_spacer)} />
                          <h5 className={clsx(styles.couponText, styles.couponText_layout)}>
                            {i18n.t('profile_coupon')}
                          </h5>
                        </Link>

                        <Link to="/profile/redeem" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.redeemNavFlex, styles.redeemNavFlex_layout)}>
                          <div className={clsx(styles.redeemNavFlex_item)}>
                            <div style={{ '--src': `url(${RedeemIcon})` }} className={clsx(styles.redeemIcon, styles.redeemIcon_layout)} />
                          </div>
                          <div className={clsx(styles.redeemNavFlex_spacer)} />
                          <h5 className={clsx(styles.redeemText, styles.redeemText_layout)}>
                            {i18n.t('profile_redemption')}
                          </h5>
                        </Link>

                        <Link to="/profile/purchase" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.orderNavFlex, styles.orderNavFlex_layout)}>
                          <div className={clsx(styles.orderNavFlex_item)}>
                            <div style={{ '--src': `url(${OrderIcon})` }} className={clsx(styles.orderIcon, styles.orderIcon_layout)} />
                          </div>
                          <div className={clsx(styles.orderNavFlex_spacer)} />
                          <h5 className={clsx(styles.orderText, styles.orderText_layout)}>
                            {i18n.t('profile_trade')}
                          </h5>
                        </Link>
                      </div>

                      <div className={clsx(styles.learningNav, styles.learningNav_layout)}>
                        <h3 className={clsx(styles.subtitle, styles.subtitle_layout)}>
                          {i18n.t('profile_learning')}
                        </h3>
                      </div>

                      <div className={clsx(styles.learningNavFlex, styles.learningNavFlex_layout)}>

                        <Link to="/profile/learned" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.historyNavFlex, styles.historyNavFlex_layout)}>
                          <div className={clsx(styles.historyNavFlex_item)}>
                            <div style={{ '--src': `url(${HistoryIcon})` }} className={clsx(styles.historyIcon, styles.historyIcon_layout)} />
                          </div>
                          <div className={clsx(styles.historyNavFlex_spacer)} />
                          <h5 className={clsx(styles.historyText, styles.historyText_layout)}>
                            {i18n.t('profile_history')}
                          </h5>
                        </Link>

                        <Link to="/profile/interests" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.favoNavFlex, styles.favoNavFlex_layout)}>
                          <div className={clsx(styles.favoNavFlex_item)}>
                            <div style={{ '--src': `url(${FavoIcon})` }} className={clsx(styles.favoIcon, styles.favoIcon_layout)} />
                          </div>
                          <div className={clsx(styles.favoNavFlex_spacer)} />
                          <h5 className={clsx(styles.favoText, styles.favoText_layout)}>
                            {i18n.t('profile_interest')}
                          </h5>
                        </Link>

                      </div>

                      <div className={styles.spacer} />

                      <div className={clsx(styles.naviContainer_item)}>
                        <div
                          onClick={this.handlerSignOut}
                          className={clsx(styles.logoutBtn, styles.logoutBtn_layout)}
                        >
                          <div className={clsx(styles.logoutFlex, styles.logoutFlex_layout)}>
                            <div style={{ '--src': `url(${LogoutIcon})` }} className={clsx(styles.logoutIcon, styles.logoutIcon_layout)} />
                            <div className={clsx(styles.logoutFlex_spacer)} />
                            <h5 className={clsx(styles.logoutText, styles.logoutText_layout)}>
                              {i18n.t('profile_logout')}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery maxHeight={699}>
                <div className={clsx(styles.container, styles.miniContainer)} style={width < 576 ? { top: headerHeight } : { top: 63 }}>
                  <div className={clsx(styles.dropDownContainer)}>
                    <div style={{ '--src': `url(${bubbleBg})` }} className={clsx(styles.infoContainer, styles.infoContainer_layout)}>
                      <div className={clsx(styles.infoContent, styles.infoContent_layout)}>
                        <div className={clsx(styles.infoFlex, styles.infoFlex_layout)}>
                          <div className={clsx(styles.infoFlex_item)}>
                            <div style={{ '--src': `url(${avatar ?? defaultAvatar})` }} className={clsx(styles.userIcon, styles.userIcon_layout)} />
                          </div>
                          <div className={clsx(styles.infoFlex_spacer)} />
                          <div className={clsx(styles.infoFlex_item1)}>
                            <div className={clsx(styles.userInfo, styles.userInfo_layout)}>
                              <h5 className={clsx(styles.userName, styles.userName_layout)}>
                                {nickname}
                              </h5>
                              <div className={clsx(styles.userPoint, styles.userPoint_layout)}>
                                {`${i18n.t('profile_my_point_left')} ${points} ${i18n.t('profile_my_point_left_unit')}`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={clsx(styles.naviContainer, styles.naviContainer_layout, styles.mininavi)}>
                      <div className={clsx(styles.accountNav, styles.accountNav_layout)}>
                        <h3 className={clsx(styles.accountText, styles.accountText_layout)}>
                          {i18n.t('profile_my_account')}
                        </h3>
                      </div>

                      <div className={clsx(styles.accountNavFlex, styles.accountNavFlex_layout)}>
                        <Link
                          to="/profile/information"
                          onClick={this.menuVM.handlerCloseMenu}
                          className={clsx(styles.basicInfoFlex, styles.basicInfoFlex_layout)}
                        >
                          <div className={clsx(styles.basicInfoFlex_item)}>
                            <div style={{ '--src': `url(${BasicInfoIcon})` }} className={clsx(styles.basicInfoIcon, styles.basicInfoIcon_layout)} />
                          </div>
                          <div className={clsx(styles.basicInfoFlex_spacer)} />
                          <h5 className={clsx(styles.basicInfoText, styles.basicInfoText_layout)}>
                            {i18n.t('profile_basic_information')}
                          </h5>
                        </Link>

                        <Link
                          to="/profile/subscription-status"
                          onClick={this.menuVM.handlerCloseMenu}
                          className={clsx(styles.basicInfoFlex, styles.basicInfoFlex_layout)}
                        >
                          <div className={clsx(styles.basicInfoFlex_item)}>
                            <div style={{ '--src': `url(${SubscriptionIcon})` }} className={clsx(styles.basicInfoIcon, styles.basicInfoIcon_layout)} />
                          </div>
                          <div className={clsx(styles.basicInfoFlex_spacer)} />
                          <h5 className={clsx(styles.basicInfoText, styles.basicInfoText_layout)}>
                            {i18n.t('profile_subscription_status')}
                          </h5>
                        </Link>

                        <Link to="/profile/coupon" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.couponFlex, styles.couponFlex_layout)}>
                          <div className={clsx(styles.couponFlex_item)}>
                            <div style={{ '--src': `url(${(CouponIcon)}` }} className={clsx(styles.couponIcon, styles.couponIcon_layout)} />
                          </div>
                          <div className={clsx(styles.couponFlex_spacer)} />
                          <h5 className={clsx(styles.couponText, styles.couponText_layout)}>
                            {i18n.t('profile_coupon')}
                          </h5>
                        </Link>

                        <Link to="/profile/redeem" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.redeemNavFlex, styles.redeemNavFlex_layout)}>
                          <div className={clsx(styles.redeemNavFlex_item)}>
                            <div style={{ '--src': `url(${RedeemIcon})` }} className={clsx(styles.redeemIcon, styles.redeemIcon_layout)} />
                          </div>
                          <div className={clsx(styles.redeemNavFlex_spacer)} />
                          <h5 className={clsx(styles.redeemText, styles.redeemText_layout)}>
                            {i18n.t('profile_redemption')}
                          </h5>
                        </Link>

                        <Link to="/profile/purchase" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.orderNavFlex, styles.orderNavFlex_layout)}>
                          <div className={clsx(styles.orderNavFlex_item)}>
                            <div style={{ '--src': `url(${OrderIcon})` }} className={clsx(styles.orderIcon, styles.orderIcon_layout)} />
                          </div>
                          <div className={clsx(styles.orderNavFlex_spacer)} />
                          <h5 className={clsx(styles.orderText, styles.orderText_layout)}>
                            {i18n.t('profile_trade')}
                          </h5>
                        </Link>
                      </div>

                      <div className={clsx(styles.learningNav, styles.learningNav_layout)}>
                        <h3 className={clsx(styles.subtitle, styles.subtitle_layout)}>
                          {i18n.t('profile_learning')}
                        </h3>
                      </div>

                      <div className={clsx(styles.learningNavFlex, styles.learningNavFlex_layout)}>

                        <Link to="/profile/learned" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.historyNavFlex, styles.historyNavFlex_layout)}>
                          <div className={clsx(styles.historyNavFlex_item)}>
                            <div style={{ '--src': `url(${HistoryIcon})` }} className={clsx(styles.historyIcon, styles.historyIcon_layout)} />
                          </div>
                          <div className={clsx(styles.historyNavFlex_spacer)} />
                          <h5 className={clsx(styles.historyText, styles.historyText_layout)}>
                            {i18n.t('profile_history')}
                          </h5>
                        </Link>

                        <Link to="/profile/interests" onClick={this.menuVM.handlerCloseMenu} className={clsx(styles.favoNavFlex, styles.favoNavFlex_layout)}>
                          <div className={clsx(styles.favoNavFlex_item)}>
                            <div style={{ '--src': `url(${FavoIcon})` }} className={clsx(styles.favoIcon, styles.favoIcon_layout)} />
                          </div>
                          <div className={clsx(styles.favoNavFlex_spacer)} />
                          <h5 className={clsx(styles.favoText, styles.favoText_layout)}>
                            {i18n.t('profile_interest')}
                          </h5>
                        </Link>

                      </div>

                      <div className={styles.spacer} />

                      <div className={clsx(styles.naviContainer_item)}>
                        <div
                          onClick={this.handlerSignOut}
                          className={clsx(styles.logoutBtn, styles.logoutBtn_layout)}
                        >
                          <div className={clsx(styles.logoutFlex, styles.logoutFlex_layout)}>
                            <div style={{ '--src': `url(${LogoutIcon})` }} className={clsx(styles.logoutIcon, styles.logoutIcon_layout)} />
                            <div className={clsx(styles.logoutFlex_spacer)} />
                            <h5 className={clsx(styles.logoutText, styles.logoutText_layout)}>
                              {i18n.t('profile_logout')}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MediaQuery>
            </>
          )
        }
      </div>
    );
  }
}

export default withProfile(ProfileNavView, false);
